import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Services from '../components/services'
import lang from '../data/en.json'

const ServicesEsPage = ({ location, data }) => (
  <Layout
    contrast
    isServices
    lang={lang}
    location={location}
    servicesData={data.services.nodes}
    component={Services}
    logo={data.logo.sizes}
    images={data.allImageSharp.nodes.map(node => node.sizes)}
  />
)

export default ServicesEsPage

export const pageQuery = graphql`
  query ServicesEnQuery {
    logo: imageSharp(fluid: { originalName: { eq: "logo.png" }}) {
      sizes(quality: 100, maxWidth: 75, maxHeight: 50, cropFocus: CENTER){
        ...GatsbyImageSharpSizes_withWebp
      }
    }
    allImageSharp(
      filter: { fluid: { originalName: { glob: "{bg,services,laptop}*.{png,jpg}" } } }
    ) {
      nodes {
        sizes(quality: 100, maxWidth: 1800) {
          ...GatsbyImageSharpSizes_withWebp
          originalImg
          originalName
        }
      }
    }
    services: allSitePage(
      sort: { order: ASC, fields: context___serviceData___index }
      filter: { component: { glob: "**/service.en.js" } }
    ) {
      nodes {
        path
        context {
          serviceData {
            index
            name
            htmlName
            image
            description
            extendedDescription
            items
          }
        }
      }
    }
  }
`
